<template>
  <v-form @submit.prevent="submit" v-model="valid" ref="form" class="form">
    <h2 class="form__title">Mes coordonnées</h2>
    <v-row>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          v-model="formData.address"
          outlined
          label="Adresse"
          type="text"
          persistent-placeholder
          dense
          placeholder="Adresse"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          v-model="formData.phone"
          outlined
          label="Téléphone"
          type="text"
          persistent-placeholder
          dense
          placeholder="Téléphone"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          v-model="formData.city"
          outlined
          label="Ville"
          type="text"
          persistent-placeholder
          dense
          placeholder="Ville"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          v-model="formData.email"
          outlined
          label="Adresse E-mail"
          type="email"
          persistent-placeholder
          dense
          readonly
          disabled
          :placeholder="$t('placeholders.email')"
          :rules="[validations.email, validations.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          v-model="formData.zip_code"
          outlined
          label="Code postal"
          type="text"
          persistent-placeholder
          dense
          placeholder="Code postal"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="text-center pt-4">
      <v-btn
        color="info"
        depressed
        type="submit"
        :loading="loading"
        :disabled="loading"
      >
        {{ $t("userActions.confirm") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { UserProfileModel } from "@/models/user.model.js";
import { UserService } from "@/services/user.service.js";
import validations from "@/validations/index";
export default {
  name: "ContactForm",
  data() {
    return {
      loading: false,
      valid: false,
      formData: new UserProfileModel(this.$store.state.authModule.userProfile),
    };
  },
  computed: {
    validations() {
      return validations;
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;
      UserService.updateMe(this.formData)
        .then(({ data }) => {
          this.$store.commit("authModule/SET_USER_PROFILE", data);
          this.formData = new UserProfileModel(data);
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.update")
          );
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
</style>